import React from "react";
import { graphql } from "gatsby";
import CareerLinkBox from "../../components/career-link-box";
import PathwaysIntro from "../../components/pathways-intro";
import CaptionWithLink from "../../components/caption-with-link";
import HeroPage from "../../components/hero-page";
import Pathways from "../../components/pathways";
import Seo from "../../components/seo";

const WoodPublishing = ({ data }) => {
  return (
    <>
      <Seo
        title="Wood, Wood Products and Publishing"
        description="Industries found within the cluster include sawmills, truss manufacturing, stationery product manufacturing, printing machinery & equipment manufacturing, bookstores and greeting card publishers"
        featuredImage={data.opengraphImage.sharp.image}
      />

      <HeroPage
        image={data.heroImage.sharp.fluid}
        title="work"
        caption="Bradford Sawmill, McKean County"
        position="45%"
      />
      <main>
        <section className="page-intro">
          <div className="intro-title">
            <h2>Wood, Wood Products and Publishing</h2>

            <p>
              The Wood, Wood Products and Publishing (WWP) cluster incorporates
              a supply line from raw material to finished product.
            </p>
          </div>

          <div className="intro-text">
            <p>
              It includes logging and lumber production, through the production
              of paper, to the publishing of magazines and newspapers.
            </p>

            <p>
              Industries found within the cluster include sawmills, truss
              manufacturing, stationery product manufacturing, printing
              machinery & equipment manufacturing, bookstores and greeting card
              publishers.
            </p>
          </div>
        </section>

        <CareerLinkBox
          image={data.careerImage.sharp.image}
          caption={
            <CaptionWithLink
              caption="Hancock Forest Management, North Central Region"
              courtesy="AHUG"
              url="http://ahug.com"
            />
          }
        />
      </main>

      <PathwaysIntro
        industry="Wood, Wood Products and Publishing"
        image={data.pathwaysImage.sharp.image}
        caption={
          <CaptionWithLink
            caption="Ken Spaich, McKean County"
            courtesy="AHUG"
            url="http://ahug.com"
          />
        }
      />

      <Pathways pathway={data.pathways.pathway} />
    </>
  );
};

export const data = graphql`
  query {
    pathways: pathwaysYaml {
      pathway: wood {
        name
        associate {
          name
          occupations
          salary
          skills
        }
        diploma {
          name
          occupations
          salary
          skills
        }
        certificate {
          name
          occupations
          salary
          skills
        }
        bachelor {
          name
          occupations
          salary
          skills
        }
      }
    }
    careerImage: file(
      relativePath: { eq: "10967_HancockForestManage_ab_133.jpg" }
    ) {
      sharp: childImageSharp {
        image: gatsbyImageData
      }
    }

    pathwaysImage: file(relativePath: { eq: "IMG_2135c.jpg" }) {
      sharp: childImageSharp {
        image: gatsbyImageData
      }
    }

    heroImage: file(relativePath: { eq: "10966_BradfordForestab_109.jpg" }) {
      sharp: childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }

    opengraphImage: file(relativePath: { eq: "10966_BradfordForestab_109.jpg" }) {
      sharp: childImageSharp {
        image: gatsbyImageData(layout: FIXED, width: 1200)
      }
    }

  }
`;
export default WoodPublishing;
